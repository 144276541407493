import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import Main from './pages/main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
    <BrowserRouter>
      <Routes>
        <Route element={<Main />} path='/' />
      </Routes>
    </BrowserRouter>  
);

