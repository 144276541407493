import React from "react";
import useTypewriterEffect, {
    getTypewriter,
    useCursor,
} from "use-typewriter-effect";

export default function About(props) {
    const [state, dispatch, isTyping] = useTypewriterEffect();
    const cursor = useCursor(isTyping);
    const textColor = "text-[#2D9960]"
    React.useEffect(() => {
        getTypewriter(dispatch)
            .pauseFor(5000)
            .type("Dev Full Stack, PHP(Laravel), React, Flutter e Python.")
            .trigger();
    }, [dispatch]);
    return (
        <>
            <div className="flex flex-col items-center h-screen bg-[#040F0C]">
                <div className="flex flex-col justify-center h-screen">
                    <span className={`block ${textColor} text-2xl md:text-3xl animate-text-fade-in`}>Olá! Meu Nome é </span>
                    <span className={`block ${textColor} text-5xl md:text-6xl font-semibold animate-text-fade-in-up`}>Lucas Miguel</span>
                    <span className={`block ${textColor} text-lg md:text-xl animate-text-fade-in-up`}>Sou programador e apaixonado por tecnologia!</span>
                    <div>
                        <p>
                            <span className={`${textColor} break-words`}>{state}</span>
                            <span className={`${textColor} animate-cursor2-fade-in`} style={{ visibility: cursor ? "visible" : "hidden" }}>|</span>
                        </p>
                    </div>
                </div>
                <a href="#Projects">
                    <div className="flex flex-col items-center justify-end animate-bounce">
                        <div className={`text-[#2D9960]/40 `}>Projetos</div>
                        <svg className="fill-[#2D9960]/40 w-7" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M56.8663 23.3012L35 44.6162L13.1338 23.3012C12.7431 22.9196 12.2186 22.706 11.6725 22.706C11.1264 22.706 10.6019 22.9196 10.2113 23.3012C10.0221 23.4866 9.8718 23.7078 9.76921 23.952C9.66662 24.1961 9.61377 24.4583 9.61377 24.7231C9.61377 24.9879 9.66662 25.2501 9.76921 25.4943C9.8718 25.7384 10.0221 25.9596 10.2113 26.145L33.4731 48.825C33.8816 49.2232 34.4295 49.446 35 49.446C35.5705 49.446 36.1184 49.2232 36.5269 48.825L59.7888 26.1494C59.9793 25.9639 60.1307 25.7421 60.2341 25.4971C60.3374 25.2522 60.3907 24.989 60.3907 24.7231C60.3907 24.4572 60.3374 24.194 60.2341 23.9491C60.1307 23.7041 59.9793 23.4823 59.7888 23.2969C59.3981 22.9153 58.8736 22.7016 58.3275 22.7016C57.7814 22.7016 57.2569 22.9153 56.8663 23.2969V23.3012Z" />
                        </svg>
                    </div>
                </a>
            </div>
        </>
    )
}