import React from "react";
import pocuzIcon from "../images/projects_logos/PocuzIcon.png"
import ticTacIcon from "../images/projects_logos/ticTac.png"
import sdgpIcon from "../images/projects_logos/sdgp.png"
import hamiltonIcon from "../images/projects_logos/hamilton_logo.png"


/**
 * Função para retornar a seção de projetos
 * @param {parametros utilizados nos cards} props 
 * @returns 
 */
export default function Projects(props) {
    return (
        <div id="Projects">
            <div className="grid md:h-screen h-auto pt-12 pb-12 md:pt-0 md:pb-0 pl-12 pr-12 bg-[#E9F5DB] shadow-inner-top-bottom">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 content-center">
                    <Card
                        image={pocuzIcon}
                        name="Pocuz"
                        text="Aplicação desenvolvidade em Python e Qt. Usada para auxiliar na utilização da técnica de pomodoro."
                        link="https://github.com/LucasMiguel/pocuz"
                    />
                    <Card
                        image={ticTacIcon}
                        name="Jogo da Velha"
                        text="Jogo da velha para terminal Linux. Desenvolvido em C/C++."
                        link="https://github.com/LucasMiguel/Jogo_da_Velha"
                    />
                    <Card
                        image={sdgpIcon}
                        name="S.D.G.P"
                        text="Aplicativo mobile desenvolvido em Flutter(Dart). Auxilia no gerenciamento e criação de listas de compras."
                        link="https://github.com/LucasMiguel/SDGP"
                    />
                    <Card
                        image={hamiltonIcon}
                        name="Hamilton Matemática"
                        text="Site institucional para o curso Hamilton Matemática. Site desenvolvido em Laravel e Bootstrap 5."
                        link="https://hamiltonmatematica.com.br/"
                    />
                </div>
            </div>
        </div>
    )
}

/**
 * Função para criação dos cards
 */
function Card(props) {
    return (
        <>
            <div className="z-0 group flex relative items-center justify-center space-x-5 h-52 bg-[#0B2C24] rounded-3xl border-black border-4 transition duration-700 hover:scale-105">
                <img className="w-20 h-20 md:w-24 md:h-24   group-hover:opacity-5 transition duration-500" src={props.image} alt="Logo" />
                <div className="w-1/2 group-hover:opacity-10 text-white text-2xl md:text-3xl lg:text-4xl font-bold transition duration-500">{props.name}</div>
                <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500">
                    <p className="text-white text-xl font-semibold">{props.text}</p>
                    <div className="flex gap-2 mt-3 mr-4 justify-end items-center">
                        <a className="text-[#4ADD4B] hover:text-[#2D9960] font-bold" href={props.link} target="_blank" rel="noreferrer">Veja Mais</a>
                        <svg className="fill-[#4ADD4B]" width="15" height="15" viewBox="0 0 225 225" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 112.5C13 109.185 14.3102 106.007 16.6425 103.663C18.9747 101.319 22.1379 100.002 25.4361 100.002L169.521 100.002L116.12 46.3619C114.964 45.1999 114.047 43.8205 113.421 42.3022C112.795 40.784 112.473 39.1568 112.473 37.5135C112.473 35.8702 112.795 34.243 113.421 32.7248C114.047 31.2066 114.964 29.8271 116.12 28.6651C117.276 27.5031 118.649 26.5814 120.16 25.9525C121.671 25.3237 123.29 25 124.925 25C126.56 25 128.179 25.3237 129.69 25.9525C131.201 26.5814 132.574 27.5031 133.73 28.6651L208.346 103.652C209.505 104.813 210.423 106.192 211.05 107.71C211.677 109.228 212 110.856 212 112.5C212 114.144 211.677 115.772 211.05 117.29C210.423 118.808 209.505 120.187 208.346 121.348L133.73 196.335C131.395 198.682 128.227 200 124.925 200C121.623 200 118.455 198.682 116.12 196.335C113.785 193.988 112.473 190.805 112.473 187.486C112.473 184.168 113.785 180.985 116.12 178.638L169.521 124.998L25.4361 124.998C22.1379 124.998 18.9747 123.681 16.6425 121.337C14.3102 118.993 13 115.815 13 112.5Z" />
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}