import React from "react";
import About from "../components/about";
import NavBar from "../components/navbar";
import Projects from "../components/projects";


export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div>
                <NavBar selected={1} />
                <About />
                <Projects/>
            </div>
        )
    }

}